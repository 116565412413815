.page-error {
    background: $brandDarkGreen;
    color: $white;

    .content-error {
        text-align: center;
        padding: 3rem 0;
        @include media-breakpoint-up(lg) {
            padding: 10rem 0;
            text-align: inherit;
        }
    }

    .error-number {
        font-size: 6rem;
        border-bottom: 0.5rem solid $white;
        @include media-breakpoint-up(lg) {
            padding-right: 3rem;
            border-bottom: none;
            border-right: 0.5rem solid $white;
            text-align: right;
        }
    }

    .error-title {
        font-size: 3rem;
        font-weight: bold;
    }

    .error-description {
        font-size: 1.5rem;
    }
}